import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@Services/api.service';
import { ICodeTitle, ISearchResponse2 } from 'ets-fe-ng-sdk';
import { map } from 'rxjs';
import { StorageService } from '@Services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  baseURL = environment.apiBaseUrl + '/rest/';
  agentNo: string;
  constructor(
    public apiService: ApiService,
    private storageS: StorageService,
  ) {
    const userProfile = storageS.getItem('userProfile');
    if (userProfile && userProfile.users) {
      this.getAgentNoByClientNo(userProfile.users.clientNo).subscribe(
        (res) => (this.agentNo = res?.no ? res.no : this.agentNo),
      );
    }
  }

  getAgentNoByClientNo(clientNo: string) {
    return this.apiService.get(this.baseURL + `agent/no/${clientNo}`);
  }

  searchAgentCalender(query: any) {
    return this.apiService.get(this.baseURL + 'agent/list/calender/search', query);
  }

  searchClientByName = (name: string) => {
    return this.apiService
      .get<ISearchResponse2>(this.baseURL + 'client/search/', {
        name,
      })
      .pipe(
        map((res) =>
          res.content.map<ICodeTitle>((c: any) => {
            return {
              code: c.clientNo,
              title: c.fullName,
            };
          }),
        ),
      );
  };

  searchPolicyByClient = (ownerClientNo: string) => {
    return this.apiService
      .get(this.baseURL + 'policy/search', {
        ownerClientNo,
      })
      .pipe(map((res) => res.content as any[]));
  };

  getPoliciesByAssured = (assured: string) => {
    return this.apiService.get(this.baseURL + 'policy/assured', {
      assured,
    });
  };

  registerClaim(data: any) {
    const query = this.apiService.getRequestParse(data);
    return this.apiService.post(this.baseURL + `workflows/submit?${query}`);
  }

  getSalesActivityAnalyticByTeamCode(query: any) {
    return this.apiService.get<SUISalesActivityAnalytics>(
      this.baseURL + 'client/sales/activity/analytic',
      query,
    );
  }

  analysePendingClaimsByStatus(query?: any) {
    return this.apiService.get<any[]>(this.baseURL + 'claim/analyse-pending-claims', query);
  }

  analysePendingPayoutsByStatus(query?: any) {
    return this.apiService.get<any[]>(this.baseURL + 'payout/analyse-pending-payout', query);
  }

  checkPolicyIsClaimable = (policyNo: string, policyCode: string) => {
    return this.apiService.get<{ status: boolean }>(this.baseURL + 'policy/check/claimable', {
      policyNo,
      policyCode,
    });
  };

  getRenewalAnalytics(query: any) {
    return this.apiService.get<any[]>(this.baseURL + 'policy/renewal-list', query);
  }
}

export interface SUISalesActivityAnalytics {
  prospectsAssignedTeam: number;
  opportuntiesAssignedTeam: number;
  conversionsAssignedTeam: number;
  opportunityConversionRatioTeam: string;
  prospectConversionRatioTeam: string;
  noCallsTeam: number;
  noEmailsTeam: number;
  noVisitsTeam: number;
}
